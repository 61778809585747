<template>
  <div>
    <h2 v-html="$t('milkqualities_content_search_title')"></h2>

    <!-- ≥ Grenzwert -->
    <v-select
      id="milkqualities_content_search_animaltype"
      disabled
      v-model="SearchMilkQualityContentStore.animalTypeId"
      :label="$t('milkqualities_content_search_animaltype')"
      :items="animalTypes"
      :item-title="this.$route.params.langKey"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!--    Stichtag-->
    <dbmDatePicker :dateLabel="$t('milkqualities_content_search_date')" v-model="SearchMilkQualityContentStore.contentDate" clearable required></dbmDatePicker>
    <v-select
      id="milkqualities_content_search_ordered"
      v-model="SearchMilkQualityContentStore.ordered"
      :label="$t('milkqualities_content_search_ordered')"
      :items="activeOptions"
      :item-title="this.$route.params.langKey"
      item-value="value"
      clearable
      @keyup.enter="search"
    />
    <v-text-field
      id="milkqualities_content_search_producernumber"
      v-model="SearchMilkQualityContentStore.producerNumber"
      :label="$t('milkqualities_content_search_producernumber')"
      type="text"
      @keyup.enter="search"
    />
  </div>
</template>

<script>
import { Term } from '@/services/term'
import baseSearchVue from './baseSearch.vue'

import DbmDatePicker from '../dbmDatePicker'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'

export default {
  name: 'searchMilkQualityContent',
  extends: baseSearchVue,
  data() {
    return {
      activeOptions: [
        { nameDe: this.$t('milkqualities_content_order_true'), nameFr: '', nameIt: '', value: 1 },
        { nameDe: this.$t('milkqualities_content_order_false'), nameFr: '', nameIt: '', value: 0 }
      ]
    }
  },
  computed: {
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    },
    SearchMilkQualityContentStore() {
      return this.$store.state.SearchMilkQualityContentStore
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  methods: {
    reset() {
      this.resetFields(this.SearchMilkQualityContentStore)
    }
  },
  mounted() {
    useAnimalTypesStore().fetchAnimalTypes()
    this.initSearchStoreFromQuery(this.SearchMilkQualityContentStore, this.$route.query)
  }
}
</script>
